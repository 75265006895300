<template>
	<div class="row">
		<div class="col side-menu">
			<div class="menu-header
			">
				
			</div>
			<ul class="list-group">
				<li class="list-group-item">
					<router-link to="/panel">
						<font-awesome-icon icon="tachometer-alt" :style="{ width: '23px' }" />
						Dashboard
					</router-link>
				</li>
				<!-- <li class="list-group-item">
					<router-link to="/panel/course">
						<font-awesome-icon icon="book" :style="{ width: '23px' }" />
						Courses
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/year">
						<font-awesome-icon icon="calendar" :style="{ width: '23px' }" />
						Exam Years
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/topic">
						<font-awesome-icon icon="list" :style="{ width: '23px' }" />
						Topics
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/lecture-note">
						<font-awesome-icon icon="chalkboard" :style="{ width: '23px' }" />
						Lecture Note
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/past-question">
						<font-awesome-icon icon="book-open" :style="{ width: '23px' }" />
						Past Questions
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/test-past-question">
						<font-awesome-icon icon="book-open" :style="{ width: '23px' }" />
						Test Past Questions
					</router-link>
				</li> -->
				<li class="list-group-item">
					<router-link to="/panel/flag-question">
						<font-awesome-icon icon="book-open" :style="{ width: '23px' }" />
						Flagged Questions
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/lecture-video">
						<font-awesome-icon icon="video" :style="{ width: '23px' }" />
						Lectures Video
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/general-information">
						<font-awesome-icon icon="info-circle" :style="{ width: '23px' }" />
						General / Special Info
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/note-audio">
						<font-awesome-icon icon="volume-up" :style="{ width: '23px' }" />
						Lecture Note Audio
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/activated-member">
						<font-awesome-icon icon="user" :style="{ width: '23px' }" />
						Activated Member
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/comments">
						<font-awesome-icon icon="user" :style="{ width: '23px' }" />
						Member Comment
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/profile">
						<font-awesome-icon icon="user" :style="{ width: '23px' }" />
						Profile & Setting
					</router-link>
				</li>
				<li class="list-group-item">
					<router-link to="/panel/activation">
						<font-awesome-icon icon="key" :style="{ width: '23px' }" />
						Activation Key
					</router-link>
				</li>
				<li class="list-group-item" @click="logout">
					<font-awesome-icon icon="key" :style="{ width: '23px' }" />
					Logout
				</li>
			</ul>
		</div>
		<div class="col content-wrapper">
			<nav class="navbar navbar-light bg-white">
				<div class="container-fluid">
					<span class="navbar-brand mb-0 h1">Navbar</span>
				</div>
			</nav>
			<router-view />
		</div>
	</div>
</template>

<script>
import { storageService } from '@/services/storage.service'
export default {
	name: 'Panel',

	data() {
		return {

		};
	},
	methods: {
		logout () {
			storageService.removeItem()
			setTimeout(() => {
				this.$router.push('/login')
			}, 500)
		}
	}
};
</script>

<style scoped>
.row{
	width: 100vw;
	height: 100vh;
	margin: 0px;
}
.side-menu{
	background: var(--paul-color-lfx-secondary);
	max-width: 280px !important;
	width: 280px;
	height: 100vh;
	box-shadow: inset rgb(80 66 66 / 56%) -1px 1px 18px 0px;
	padding: 0px !important;
	position: fixed;
	overflow-y: scroll;
	left: 0;
	z-index: 1000;
	display: block;
}
.content-wrapper{
	background: var(--paul-color-light);
	max-width: calc(100vw - 280px) !important;
	min-height: 100%;
	padding: 0px;
	margin-left: 280px;
}
.navbar{
	border-bottom: 2px solid #222;
}
.side-menu .list-group, .side-menu .list-group-item{
	background: transparent !important;
	border: none !important;
}
.side-menu .menu-header{
	height: 70px;
	width: 100%;
}
.side-menu .list-group .list-group-item{
	color: #fff !important;
	border-bottom: 1px solid #fff !important;
	padding: 10px 0px 10px 15px !important;
}
.side-menu .list-group .list-group-item a{
	color: #FFFFFF !important;
	text-decoration: none !important;
}
.side-menu .list-group .list-group-item svg{
	margin-right: 10px;
}
</style>

